/**
 * Filename:  _navbar.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides styling for the NavBar component
 * Date Mod:  December 8, 2021
 *
 */

@use "../custom"as *;
@use "../components/mixins"as *;

.menu {
    background-color: $nav-background;

    .navbar-brand {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .company-font {
            padding-left: 1.5rem;
            font-size: 1.2rem;

            align-self: center;
        }

        .logo-font {
            @include logoFontFamily;
            align-self: center;
            padding-left: 1rem;

            height: 1.15rem;
            font-weight: bold;
            margin-bottom: 0.75rem;
        }

        img {
            align-self: center;
            height: 2.1em;
            width: auto;
        }
    }

    .logo-style {
        font-family: "MuseoModerno", cursive;
        font-size: 2.5rem;
        font-weight: 800;
        letter-spacing: -0.1em;
        color: $primary;
    }

    .name-style {
        font-family: 'Alegreya Sans SC', sans-serif;


        font-size: 2.15em;
        font-weight: 800;
        color: $gray-500;
        letter-spacing: -0.05em;
        padding-left: 0.5rem;
    }

    li {
        padding: 0 0.7rem;

        a {
            color: $white !important;
            text-transform: capitalize;
            font-weight: 400;

            &:hover {
                font-weight: 700;
            }

            &.active {
                color: $secondary !important;
                font-weight: 700;
            }
        }
    }
}