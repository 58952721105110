@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/mixins';

.privacy {
  padding-top: 7rem;
  background-color: rgba(#f2f2f2, 0.95);
}

#privacy-info {
  margin: 0;

  padding-bottom: 4rem;
  padding: 3rem;

  h3 {
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  li {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: -1rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
}
